<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">站内信</div>
    </div>
    <div class="card-box">
      <div class="line-box">
        <div class="title">消息标题</div>
        <el-input v-model="value" placeholder="消息标题" />
      </div>
      <div class="line-box">
        <div class="title">选择类型</div>
        <el-select v-model="type" placeholder="选择类型">
          <el-option
            v-for="item in list"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="title">增加人员</div>
        <el-select v-model="type2" placeholder="增加人员" multiple filterable>
          <el-option
            v-for="item in list2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="title">消息内容</div>
        <vue-editor v-model="content" />
      </div>

      <div class="buttons">
        <el-button class="pop-close">取消</el-button
        ><el-button class="pop-save">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: null,
      type: null,
      type2: null,
      list: [
        {
          label: "站内信",
          value: 0,
        },
        {
          label: "微信通知",
          value: 1,
        },
        {
          label: "邮件",
          value: 2,
        },
      ],
      list2: [
        {
          label: "张三",
          value: 0,
        },
        {
          label: "李四",
          value: 1,
        },
        {
          label: "王五",
          value: 2,
        },
      ],
      content: "111",
    };
  },
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 24px 10px 24px 10px;
    .line-box {
      margin-bottom: 24px;
      .title {
        margin-bottom: 10px;
      }
      .el-select {
        width: 100%;
      }
    }
    .buttons {
      width: 100%;

      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>